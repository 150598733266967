import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5706690f")
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "circle-cont" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "lang" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, { color: "secondary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('menu.lang.subtitile')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_back_button)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, {
      class: "ion-padding",
      id: "nav-child-content"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { class: "langs-grid" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.saved, (lang) => {
              return (_openBlock(), _createBlock(_component_ion_row, { key: lang }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([$options.checkIfActive(lang), "lang-cont"]),
                    onClick: ($event: any) => ($options.switchLang(lang))
                  }, [
                    _createVNode(_component_ion_col, {
                      class: "lang-cont-flag",
                      size: "6",
                      value: lang
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("img", {
                            class: "cover circle",
                            id: "circle-it",
                            src: '/assets/background/Flag_'+lang+'.png',
                            alt: ""
                          }, null, 8, _hoisted_3)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["value"]),
                    _createVNode(_component_ion_col, { class: "lang-cont-name" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('menu.lang.'+lang)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }),
        ($data.remaining.length>0)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              expand: "block",
              size: "slim",
              color: "secondary",
              class: "add-lang",
              onClick: $options.presentActionSheet
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('menu.lang.add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}