export const en= {
    action:{
        accept:"Accept",
        postponi:"Postpone",
        declina:"Decline",
        cancel:"Cancel",
        download:"Download",
        app:"Go to App",
        salta:"Skip",
        close:"Close",
        riprova:"Try again"
    },
    onboard:{
        lang:{
            title:"Select",
            text:"Which language do you prefer the contents to be in?"
        },
        percorsi:{
            title:"Select ",
            text:"wich path do you want to follow"
        },
        tag:{
            title:"Bring your smartphone close to the SilenceTags",
            text:"Use the APP to access the contents"
        },
        qr:{
            alternative:"Otherwise",
            title:"Scan",
            text:"Scan the QR Codes placed all around"
        },


    },
    networkerror:{
		title:"Network error",
        text:"The contents cannot be downloaded. The app is available only online!",
        action:"Try later"
    },
    storageError:{
		title:"Not enough space",
        text:"It's not possible to download all the contents. Use the app online!",
        action:"Ok"
    },
    update:{
		title:"Updates",
        text:"There are new updates available. Do you want to update the app now?"
    },
    scarica:{
		title:"Download the contents",							 
        text:"The application will be available shortly. This may take some time.",
        errorconn:{
            title:"Download error",
            text:"A download problem was encountered, please check your connection and try again"
        },
        alertannulla:{
            title:"Attention",
            text:"The contents have not been downloaded, the application can only work online.</br> Please try again later to download for a better experience"
        }
    },
    capting:[
        "Looking for you", 
        "Hold the phone still",
        "One moment more..."
    ],
    main:{

        scan:"Scan QR code",
        start:"Scan SilenceTag",
        stop:"Stop SilenceTag",
        alertApp:{
            title:"Feature not available",
            text:"This feature is only available on the app",
        },
		timeoutTagTitle:"ATTENTION",
		timeoutTagText:"SilenceTag NOT detected, it may be off. Use the \"Scan QR Code\" button",
		timeoutTagAccept:"Continue",
      
    
    },

    menu:{
        title:"Settings",
        content:{
            title:"Update content"
        },
        percorsi:{
            title:"Itineraries",
            subtitile:"Downloaded itineraries",
            change:"Change itinerary",
            add:"Add new itinerary",
            alert:"Attention: this operation will download the contents of the new itinerary",
            select:"Active itineraries",
        },
        lang:{
            title:"Language",
            subtitile:"Dowloaded languages",
            add:"Add new language",
            select:"Available content",
            alert:"Attention, the contents will be downloaded in the language that you have chosen",
            it:"Italian",
            en:"English",
            fr:"French",
            de:"German",
            es:"Spanish"
        },
        help:"Help",
        copyright:"Copyright & Privacy",
        privacy:"Privacy",
        termini:"Terms of Service",
        test:{
            title:"Test",
            inizioTest:"Start TEST",
            stopTest:"Stop TEST",
            testoIstruzioni:"Unplug your headphones, turn up the volume, and run the test",
            compatibileTest:"The device is compatible",
            nonCompatibileTest:"The device is not compatible",
        }
        
    },
    raccolta:{
        title:"Collection",
        subtitle:"Live the experience again",
        alerttext:"No content has been viewed yet. \n All contents viewed throughout the visit will be available here"
    },
    schede:{
        back:"Back"
    }
   
}